// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import 'highlight.js/styles/default.css'; // 或者选择其他样式

const app = createApp(App)
app.use(router)
app.use(ViewUIPlus)
app.mount('#app')
