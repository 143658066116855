<template>
  <div class="main">
    <AppNavbar />
    <div class="container">
      <router-view />
    </div>
    <AppFooter />
    <BackTop :bottom="100" />
  </div>
</template>

<script>
import AppNavbar from './components/AppNavbar.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter
  }
}
</script>
<style>
body{
  background: #f5f7f9;
}
.main{
  padding:0;
  margin:0;
}
.container{
  padding:20px;
}
</style>
