<template>
  <Row class="nav-main">
    <Row class="logo">
      <Col>常用工具</Col>
    </Row>
    <Menu
      mode="horizontal"
      theme="dark"
      :default-active="activeIndex"
      @on-select="selectMenu"
    >
      <Submenu name="3">
        <template #title>
          <Icon type="ios-construct" />
          SQL处理
        </template>
        <MenuItem name="/">sql转struct</MenuItem>
      </Submenu>
      <Submenu name="1">
        <template #title>
          <Icon type="md-unlock" />
          加/解密
        </template>
        <MenuItem name="/secret/md5">MD5</MenuItem>
        <MenuItem name="/secret/base64">base64</MenuItem>
      </Submenu>
      <Submenu name="2">
        <template #title>
          <Icon type="logo-javascript" />
          JSON处理
        </template>
        <MenuItem name="/json/parse">Json解析</MenuItem>
      </Submenu>
    </Menu>
  </Row>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

// Route-to-Index Mapping
const routeToIndex = {
  '/': '3',
  '/secret/md5': '1',
  '/secret/base64': '1'
  // Ensure these values match your MenuItem names
}

// Computed property for activeIndex
const activeIndex = computed(() => routeToIndex[route.path] || '3')

function selectMenu(path) {
  router.push(path).catch(err => {
    console.error('Navigation error:', err)
    // Handle the error, e.g., show an error message or redirect to a fallback route
  })
}
</script>

<style scoped>
.nav-main {
  background: #515a6e;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
}

.ivu-menu-dark {
  background: #515a6e;
}

.ivu-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.logo {
  font-size: 22px;
  color: #fff;
  padding: 0 10px;
}
</style>
