// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: () => import('@/views/home/index')
  },
  {
    path: '/secret',
    hidden: false,
    name: 'SecretIndex',
    meta: {
      title: '加/解密',
      icon: 'el-icon-cherry'
    },
    redirect: '/secret/base64', // 添加重定向规则
    children: [
      {
        hidden: false,
        meta: { title: 'base64', icon: 'el-icon-cherry' },
        path: '/secret/base64',
        name: 'SecretBase64',
        component: () => import('@/views/secret/base64')
      },
      {
        hidden: false,
        meta: { title: 'md5', icon: 'el-icon-cherry' },
        path: '/secret/md5',
        name: 'SecretMd5',
        component: () => import('@/views/secret/md5')
      }
    ]
  },
  {
    path: '/json',
    hidden: false,
    name: 'JsonIndex',
    meta: {
      title: 'json',
      icon: 'el-icon-cherry'
    },
    redirect: '/json/parse', // 添加重定向规则
    children: [
      {
        hidden: false,
        meta: { title: 'base64', icon: 'el-icon-cherry' },
        path: '/json/parse',
        name: 'JsonParse',
        component: () => import('@/views/json/parse')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(), // Enable HTML5 history mode
  routes
})

export default router
